<template>
    <div>
        <ul class="faq_category">
            <li>
                <span class="category_tab" :class="activeTab === 'Howtouse' ? 'on' : ''"
                      @click="clickActiveTab('Howtouse')">이용방법</span>
            </li>
            <li>
                <span class="category_tab" :class="activeTab === 'Payment' ? 'on' : ''" @click="clickActiveTab('Payment')">구독 및
                    결제</span>
            </li>
            <li>
                <span class="category_tab" :class="activeTab === 'Profile' ? 'on' : ''"
                      @click="clickActiveTab('Profile')">프로필관리</span>
            </li>
            <li>
                <span class="category_tab" :class="activeTab === 'Etc' ? 'on' : ''" @click="clickActiveTab('Etc')">기타</span>
            </li>
        </ul>
        <ul class="select_faq_list">
            <li class="faq_selest_item">
                <ul class="select_faq_item" v-for="(selectItem, index) in getFaqItemList" :key="selectItem.faqId"
                    @click="activeDetail(index)">
                    <li class="faq_item_title_wrap">
                        <h1>{{ selectItem.faqTitle }}</h1>
                        <span class="arrow_img_wrap">
                            <img class="arrow_img" src="/media/images/faq/ico_arrow_down.png" alt="아래버튼">
                        </span>
                    </li>
                    <li class="faq_item_detail" :class="activeFaqItem === index ? 'on' : ''">
                        <div class="faq_contents_wrap">
                            <span class="contents_order">A.</span>
                            <div class="contents_text" v-html="selectItem.faqContent">

                            </div>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
        <infinite-loading v-if="isLoad" @infinite="infiniteHandler" :identifier="infiniteId" spinner="waveDots">
            <template #no-more>
                <span></span>
            </template>
            <template #no-results>
                <span></span>
            </template>
        </infinite-loading>
    </div>
</template>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
export default {
  name: 'FaqListComponent',
  components: {
    'infinite-loading': InfiniteLoading
  },
  data () {
    return {
      limit: 0,
      activeTab: 'Howtouse',
      activeFaqItem: -1,
      selectFaqList: [],
      infiniteId: new Date(),
      isLoad: false
    };
  },

  async created () {
    await this.getFaqListApi();
    this.isLoad = true;
  },

  mounted () {
  },

  methods: {
    async clickActiveTab (index) {
      this.activeTab = index;
      this.activeFaqItem = -1;
      this.resetArrow();
      this.isLoad = false;
      this.selectFaqList = [];
      this.limit = 0;
      await this.getFaqListApi();
      this.isLoad = true;
    },
    activeDetail (index) {
      const list = document.querySelector('.faq_selest_item');

      const img = list.childNodes[index].querySelector('.faq_item_title_wrap img');
      if (index === this.activeFaqItem) {
        this.activeFaqItem = -1;
        img.classList.remove('on');
        img.classList.add('off');
      } else {
        this.activeFaqItem = index;
        img.classList.remove('off');
        img.classList.add('on');
      }
      list.childNodes.forEach((childItem, childIndex) => {
        if (childIndex !== index) {
          const img = childItem.querySelector('.faq_item_title_wrap img');
          img.classList.remove('on');
          img.classList.add('off');
        }
      });
    },
    resetArrow () {
      const list = document.querySelector('.faq_selest_item');
      list.childNodes.forEach((childItem) => {
        const img = childItem.querySelector('.faq_item_title_wrap img');
        img.classList.remove('on');
        img.classList.add('off');
      });
    },
    async getFaqListApi () {
      return await axios.post('/api/menu/getFaqList', { faqCategory: this.activeTab, limit: this.limit })
        .then(res => {
          this.limit += 10;
          this.selectFaqList = [...this.selectFaqList, ...res.data.result];
          return res.data.result;
        })
        .catch(err => {
          console.log(err);
          return [];
        });
    },
    async infiniteHandler ($state) {
      const result = await this.getFaqListApi();
      if (result.length > 0) {
        setTimeout(() => {
          $state.loaded();
        }, 1000);
      } else {
        $state.complete();
      }
    }
  },
  computed: {
    getFaqItemList () {
      return this.selectFaqList.map((faqItem) => {
        return {
          faqCategory: faqItem.faqCategory,
          faqContent: faqItem.faqContent.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>"),
          faqId: faqItem.faqId,
          faqTitle: faqItem.faqTitle,
          faqType: faqItem.faqType,
          registDate: faqItem.registDate,
          updateDate: faqItem.updateDate,
          useAt: faqItem.useAt
        };
      });
    }
  }
};
</script>
<style scoped>
.faq_category {
  display: flex;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.category_tab {
  font-size: 16px;
  line-height: 2;
  color: var(--white);
  display: inline-block;
  padding: 4px 16px;
  border-radius: 200px;
  background: rgba(255, 255, 255, 0.04);
  margin-right: 16px;
  cursor: pointer;
}

.category_tab.on {
  background-color: var(--secondary);
  font-weight: 700;
}

.select_faq_list {
  margin-top: 42px;
}

.select_faq_item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  padding-bottom: 41px;
  padding-top: 32px;
  cursor: pointer;
}

.select_faq_item:first-child {
  padding-top: 0;
}

.arrow_img_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq_item_title_wrap {
  display: flex;
  justify-content: space-between;
}

.faq_item_title_wrap h1 {
  color: rgba(255, 255, 255, 0.87);
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;
}

.faq_item_title_wrap .arrow_img {
  width: 24px;
  height: 24px;
  cursor: pointer;

}

.faq_item_detail {
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease-out;
}

.faq_item_detail.on {
  max-height: 100vh;
  transition: all 4s ease-out;
}

.arrow_img.off {
  animation: off 0.3s;
}

.arrow_img.on {
  transform: rotate(180deg);
  animation: rotation 0.3s;
}

.faq_contents_wrap {
  display: flex;
  justify-content: space-between;
}

.contents_order {
  display: inline-block;
  width: 25px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: var(--secondary);
}

.contents_text {
  display: inline-block;
  width: calc(100% - 30px);
  color: rgba(255, 255, 255, 0.64);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  word-break: break-all;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes off {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

/*######################## 반응형 처리 ~1024px ########################*/
@media all and (max-width: 1024px) {
  .faq_category {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }

}

/*######################## 반응형 처리 ~500px ########################*/
@media all and (max-width: 500px) {

  .faq_category .category_tab {
    display: inline-block;
    max-width: 250px;
  }

  .faq_category .category_tab {
    text-align: center;
  }

  .faq_category li:first-child .category_tab {
    width: 81px;
  }

  .faq_category li:nth-child(2) .category_tab {
    width: 98px;
  }

  .faq_category li:nth-child(3) .category_tab {
    width: 96px;
  }

  .faq_category li:last-child .category_tab {
    width: 63px;
  }

  .category_tab {
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: rgba(255, 255, 255, 0.08);
    margin-right: 6px;
    font-size: 14px;
    padding: 6px 12px;
  }

  .select_faq_list {
    margin-top: 14px;
  }

  .faq_item_title_wrap h1 {
    font-size: 16px;
    line-height: 1.2;
  }

  .select_faq_item {
    padding-bottom: 16px;
  }

  .contents_order {
    font-size: 14px;
  }

  .contents_text {
    font-size: 14px;
  }
}
</style>
